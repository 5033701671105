/* body{
  overflow-y: hidden;
} */

.card-header {
    background-color: #ffffff !important;
}

.chart-pie {
    height: auto !important;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: static;
  /* width: 324px; */
  height: 139px;
  left: 0px;
  top: 0px;

  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(7, 39, 124, 0.04), 0px 0px 2px rgba(7, 39, 124, 0.06), 0px 0px 1px rgba(7, 39, 124, 0.04);
  border-radius: 16px;
}

.card-breaker {
  display: block;
  height: 1px;
  width: 100%;
}

.dashboard-card {
  flex: 1 1 10px;
  margin: 5px 20px;
}


.dashboard a:hover {
    text-decoration: none;
}
.dashboard {
    margin: 36px;
}

.height-400px{
    height: 400px;
}

.margin-top-0 {
    margin-top: 0;
}

.body-heading {
  font-weight: 800;
  font-size: 40px;
  line-height: 45px;
  color: #0F57A6;
}

.dashboard-title {
  margin: 1rem;
  color: #0D276B;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 27px;
}

/*Data Card Styles*/
.data-in-card {
  /* width: 440px; */
  height: 256px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(7, 39, 124, 0.04), 0px 0px 2px rgba(7, 39, 124, 0.06), 0px 0px 1px rgba(7, 39, 124, 0.04);
  border-radius: 16px;
}



/*Table styles*/
.grey-card-text {
  padding: 0;
  color: #A6ADB6;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 45px;
  width: 19.42px;
  height: 19.16px;
  left: 75px;
  top: 89px;
}

.scan {
  padding: 0;
  padding-top: 0.3rem;
  color: #A6ADB6;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.background-table{
  /* width: 1368px; */
  height: 373px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(7, 39, 124, 0.04), 0px 0px 2px rgba(7, 39, 124, 0.06), 0px 0px 1px rgba(7, 39, 124, 0.04);
  border-radius: 16px;
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1.25rem;

}

.table-in-card{
  /* width: 1320px; */
  height: auto;
  color: #0D276B;

}
tbody{
  font-weight: 500;
}
thead th{
  background:white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  /*box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);*/
  /*filter: drop-shadow(0px 13px 31px rgba(15, 87, 166, 0.05)), drop-shadow(0px 5.00741px 9.87407px rgba(15, 87, 166, 0.0425185)), drop-shadow(0px 1.05926px 2.52593px rgba(15, 87, 166, 0.0274815));
  */
}

.width-id {
  width: 426px;
}

.width-insight{
  width: 520px;
}
.width-update{
  width: 232px;
}
.width-risk{
  width: 144px;

}

.width-devices {
  width: 200px;
}

.width-os {
  width: 280px;
}

.width-domain{
  width: 200px;
}

.width-scan{
  width: 150px;
}
.width-risk-devices{
  width: 80px;
  /* text-align: center; */
}


.alignment {
  border-bottom: 1px solid #DBE1E8;
  padding: 7px 8px 7px 8px;
}

td {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 8px;
  vertical-align: text-top;
  border-bottom: 1px solid #DBE1E8;
}

/* td.risk{
  text-align: center;
} */

.table-link {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;


  width: 50%;
  height: 24px;
  left: 645px;
  top: 674px;

  font-family: Inter-2;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #808C9C;
}

.table-link a {
  text-decoration: none;
  color: #808C9C;
}

.flex {
  display: flex;

}

.likelihood{
  position: relative;
  margin: -15px;
  top: 90px;
  left: -10px;
  width: 75px;
  height: 15px;
  transform: rotate(-90deg);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */
  text-align: center;

  /* Silver Grey */
  color: #A6ADB6;
}

.impact {
  text-align: end;
  margin-left: auto;
  margin-right: 144px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  /* Silver Grey */
  color: #A6ADB6;

}

.table-in-card tbody tr:hover {
  background-color: #EAF1FE;
  cursor: pointer;
}



.table-in-card a{
  color: #0D276B;
  text-decoration: none;
}

.no-devices {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  color: #0D276B;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 20px;
  margin: revert;
}

.width-tenants {
  width: 70%;
}

.center-card{
  display: flex;
  justify-content: center;
  align-items: center;
}

.device-details{

}
