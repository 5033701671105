.reports-architecture-domains {

}
.business_application {

    /*min-height: 80px;*/
    /*min-width: 120px;*/
    height: 95px;
    width: 175px;
    font-size: 15px;
    margin: 5px;


    padding: 25px;
}

.business_application.critical, .business_application.high {
    /*background-color: #AF1A1A;*/
    background-color: rgba(224, 83, 64, 0.8);
    border: 1px solid transparent;
    color: #000000;
}

.business_application.low {
    /*background-color: #2982cc;*/
    background-color: rgba(54, 130, 243, 0.6);
    border: 1px solid #2982cc;
    color: #ffffff;
}

.business_application.medium {
    /*background-color: #FABA0B;*/
    background-color: rgba(255, 230, 43, 0.9);
    border: 1px solid #FABA0B;
    color: #000000;
}


.business_application.unknown {
    /*background-color: #cdcdcd;*/
    background: rgba(230, 230, 230, 0.6);
    border: 1px solid #e8e8e8;
    color: #000000;
}
.business_application.none {
    /*background-color: #cdcdcd;*/
    /*background: rgba(230, 230, 230, 0.6); */
    border: 1px solid #d0cfcf;
    color: #000000;
}


.business_application.offline {
    filter: alpha(opacity=40);
    opacity: 0.4;
    -moz-opacity: 0.4;
    background-color: #fff;
    border: 1px solid #333;
}
.business_application.offline i {
    color: red;
}