.header {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 0px!important;
    background-color: #0F57A6 !important;
    height: 85px;
}



.button-style {
    background-color: transparent;
    color: #fff;
}

.button-style:hover {
    background-color: transparent;
    color: #fff;
} 

.button-style .active{
    background-color: transparent;
}

.width {
    width: 1em !important;
}
.dropdown-item {
  color: #0D276B;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;  
  padding-right: 16px;
  padding-left: 16px;
}

.dropdown-item svg {
  margin-top: -3px;
  margin-right: 15px;
}


.dropdown-item.active, .dropdown-item:active {
    color: #0D276B;
    background-color: transparent;
}
.dropdown-item:hover {
    color: #0D276B;
    background-color: transparent;
}

.navbar {
  padding: 0.5rem 1.5rem;
}
