span.limited-text-field {
  position: relative;
}

.no-hover:hover {
  cursor: default;
}

span .hovering-full-text {
  /* visibility: hidden; */
  transition: opacity 0.1s;
  opacity: 0;
  padding: 8px;
  border-radius: 4px;
  /* box-shadow: 0px 4px 8px rgba(7, 39, 124, 0.04), 0px 0px 2px rgba(7, 39, 124, 0.06), 0px 0px 1px rgba(7, 39, 124, 0.04); */
  position: absolute;
  left: -8px;
  top: -10px;
  min-width: 180px;
  max-width: 300px;
}

tr:hover span.limited-text-field .hovering-full-text {
  background-color: #EAF1FE;
}

span.limited-text-field:hover .hovering-full-text {
  /* visibility: visible; */
  opacity: 1;
  background-color: white;
}


div.subscriber-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  width: 10rem;
}

tr:hover div.subscriber-widget input[type=text] {
  background-color: #EAF1FE;
}

div.subscriber-widget input[type=text] {
  outline: none;
  border: none;
  color: #0D276B;
  font-size: 16px;
}

div.subscriber-widget input[type=text]:hover {
  background-color: #EAF1FE;
}

div.subscriber-autocomplete-widget {
  display: flex;
  position: absolute;
  left: 0rem;
  top: 26px;
  padding: 8px 0px 8px 0px;
  background-color: #FFFFFF;
  opacity: 1;
  border: 0px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 9.38125px 43.3324px rgba(0, 0, 0, 0.0417275), 0px 5.25905px 24.2918px rgba(0, 0, 0, 0.035), 0px 2.79304px 12.9012px rgba(0, 0, 0, 0.0282725), 0px 1.16225px 5.36848px rgba(0, 0, 0, 0.0196802);
  height: auto;
}

div.subscriber-autocomplete-widget ul {
  padding-left: 0px;
  margin: 0;
  list-style-type: none;
}

div.subscriber-autocomplete-widget li {
  margin: 0;
  padding: 8px 8px 8px 8px;
  box-sizing: border-box;
}

div.subscriber-autocomplete-widget li:hover {
  background-color: #EAF1FE;
  cursor: pointer;
}

div.ticket-field {
  cursor: pointer;
}

div.ticket-field .no-hover:hover {
  cursor: default;
}

div.ticket .rm-ticket {
  margin-left: 4px;
}

tr:hover div.ticket-widget input[type=text] {
  background-color: #EAF1FE;
}

div.ticket-widget input[type=text] {
  outline: none;
  border: none;
  color: #0D276B;
  font-size: 16px;
}

div.ticket-widget input[type=text]::placeholder {
  color: #B5C1CE;
  font-size: 16px;
}

div.subscriber-widget input[type=text]:hover {
  background-color: #EAF1FE;
}

div.response-field{
  position: relative;
  cursor: pointer;
}

div.response-field .no-hover:hover {
  cursor: normal;
}

div.response-field div.response-widget {
  position: absolute;
  top: 2.5rem;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  width: 10rem;
  padding: 8px 0px 8px 0px;
  background-color: #FFFFFF;
  opacity: 1;
  border: 0px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 9.38125px 43.3324px rgba(0, 0, 0, 0.0417275), 0px 5.25905px 24.2918px rgba(0, 0, 0, 0.035), 0px 2.79304px 12.9012px rgba(0, 0, 0, 0.0282725), 0px 1.16225px 5.36848px rgba(0, 0, 0, 0.0196802);
  height: auto;
  z-index: 10;
}

div.response-field .response-widget ul {
  list-style: none;
  margin-left: 0;
  padding: 0px 8px 0px 8px;
}

div.response-field .response-widget ul li {
  padding: 4px 0 4px 0;
}

div.response-field .response-widget ul li:hover {
  background-color: #EAF1FE;
}

div.response-field .response-widget .response-done-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid #DBE1E8;
  padding: 8px 8px 2px 12px;
}

div.response-field .response-widget .response-done-container label {
  margin: 0 0 0 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0D276B;
}

div.response-field .response-widget .response-done-container input[type=checkbox] {
  position: absolute;
  left: 0;
  display: none;
}

div.response-field .response-widget .response-done-container input[type=checkbox] + label {
  position: relative;
    padding-left: 2.3em;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
}

div.response-field .response-widget .response-done-container input[type=checkbox] + label:before {
  display: flex;
  content: '';
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #808C9C;
  box-sizing: border-box;
  border-radius: 6px;
  width: 24px;
  height: 24px;
}

div.response-field .response-widget .response-done-container input[type=checkbox]:checked + label:before {
  background-color: #48A77B;
  border-color: #48A77B;
  content: '\2713';
  color: white;
}

/*  */
