.filter {
    /* border: 1px solid rgba(0, 0, 0, .125); */
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #FFFFFF;
    border-right: 1px solid #DBE1E8;
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    padding-bottom: 50px;
}


.filter::-webkit-scrollbar {
  display: none;
}

#filter-header {
  padding-top: 24px;
  position: sticky;
  top: 0px;
  background-color: #FFFFFF;
  z-index: 12;
}
#filter-footer{
  display: flex;
  position: fixed;
  bottom: 0;
  background-color: #FFFFFF;
  box-shadow: 0px -6px 10px rgba(1, 9, 74, 0.07);
  transition: margin 0.7s;
}


#filter-header .filter-searchbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin-left: 24px;
  margin-right: 24px;
  box-shadow: 0px 4px 8px rgba(7, 39, 124, 0.04), 0px 0px 2px rgba(7, 39, 124, 0.06), 0px 0px 1px rgba(7, 39, 124, 0.04);
  border-radius: 34px;
}

.filter-dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
}

.filter-dropdown-container .filter-dropdown-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 253px;
  height: 40px;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #808C9C;
  box-sizing: border-box;
  border-radius: 34px;
  padding-left: 16px;
  padding-right: 12px;
  position: relative;
}

.filter-dropdown-container .filter-dropdown-select .dropdown-text {
  color: #808C9C;
  font-size: 16px;
}

.filter-dropdown-container .filter-options-container {
  background: #FFFFFF;
  z-index: 12;
  position: absolute;
  top: 72px;
  border: 0px solid #FFFFFF;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 9.38125px 43.3324px rgba(0, 0, 0, 0.0417275), 0px 5.25905px 24.2918px rgba(0, 0, 0, 0.035), 0px 2.79304px 12.9012px rgba(0, 0, 0, 0.0282725), 0px 1.16225px 5.36848px rgba(0, 0, 0, 0.0196802);
}

.filter-dropdown-container .option {
  /* background: #ff00ff; */
  display: flex;
  flex-direction: column;
  width: 346px;
  height: 64px;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.filter-dropdown-container .option:hover {
  background-color: #EAF1FE;
}

.filter-dropdown-container .option .title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #0D276B;
}

.filter-dropdown-container .option .subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #B5C1CE;
}

#filter-header .filter-searchbar-container input[type=text] {
  /* flex: 1; */
  width: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #808C9C;
  box-sizing: border-box;
  border-radius: 34px;
  height: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  padding-right: 34px;
}

#filter-header .filter-searchbar-container input[type=text] {
  outline: none;
}

#filter-header .filter-searchbar-container .input-icon {
  margin-left: -30px;
}

.findings .col-2{
  background-color: #fff;
}

.filter-body {
    /*padding-top: 13px; */
    margin: 24px;
}

.filter .filter-collapsed {
  display: flex;
}

.filter .filter-not-collapsed {
  display: flex;
  transform: rotate(90deg);
}

.filter ul {
    padding-left: 0px;
    list-style-type: none;
}

.filter li {
    font-size: 0.8rem;
    margin-top: 9px;
    margin-right: 9px;
    margin-bottom: 9px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.breadcrumb {
    background-color: #f8f9fa;
    padding-left: 25px;
}

.container {
    /*padding-top: 40px;*/
    max-width: 100%;
}

.filter li:hover {
    cursor: pointer;
}
.filter li.active {

}

.filter-group-label {
  display: flex;
  align-items: baseline;
  padding-left: 0.3rem;
  cursor: pointer;
}

.filter h3 {
  color: #0D276B;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0px;
}


.filter li .filter-option-text {
  color: #0D276B;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 0px;
}


.filter #filter-footer {
  width: 300px;
  padding-top: 16px;
  padding-bottom: 16px;
  /* margin-bottom: -100px; */
}

.filter #filter-footer .button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: #FFFFFF;
  background-color: #0F57A6 ;
  border-radius: 34px;

}

.reset-background{
  margin-left: auto;
  margin-right: auto;
  width: 248.57px;
  background-color: #FFFFFF;
}

#filter-footer .is-open{
  margin-bottom: -250px;
  transition: all 3s ease-in-out;
}

.filter #filter-footer .button:hover {
  cursor: pointer;
  background-color: #0D276B;
}

.col-1-5 {
  flex: 0 0 20.833%;
  max-width: 301px;
  position: relative;
  width: 100%;
}

.col-sm-10{
  flex: 0 0 79%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}


.checkbox {
  border: 1px solid #808C9C;
  border-radius: 6px;
  margin-right: 8px;
  padding-left: 2px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
