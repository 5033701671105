div.dynamic-table {
  display: table-row-group;
}

div.dynamic-table tr.no-hover:hover {
  background-color: inherit;
  cursor: default;
}


div.dynamic-table tr.child-row td:first-child {
  padding-left: 2rem;
  content: "";
}

div.dynamic-table div.text-field-with-expansion {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #0D276B;
}

div.dynamic-table div.text-field-with-expansion:hover {
  cursor: pointer;
}

div.dynamic-table div.group-expanded {
  margin-right: 8px;
  transform: rotate(90deg);
}

div.dynamic-table div.group-not-expanded {
  margin-right: 8px;
}


.table-pagination {
  display: flex;
  justify-content: space-between;
  margin: 24px 0px 0px 24px;
}

.table-pagination .page-index {
  align-self: flex-start;
}

.table-pagination ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}

.table-pagination li {
  background-color: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #dddfeb;

  padding: 6px 13px 6px 13px;
  margin-right: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #808C9C;
}

.table-pagination li:hover {
  cursor: pointer;
}

.table-pagination li.active {
  background: #0F57A6;
  color: #FFFFFF;
  border: 1px solid #0F57A6;
}

.table-pagination li.not-clickable {
  cursor: default;
}

.table-pagination .page-navigation {
  align-self: flex-end;
}
