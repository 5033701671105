.landscape-item{
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF;
}

.landscape-item a{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF;

  text-decoration: none;
}

.empty-cell {
  border: none
}