.findings {
    font-size: 1em;
    background-color: #F5F7FC;
}

.findings .row {
  margin-right: 0;
  margin-left: 0;
}


.findings .btn.search {
    font-size: 0.9em;
}

.findings h2 {
    margin-top: 0;
    padding-bottom: 8px;
}

.findings .nav.nav-pills > li > a {
    border-top: 3px solid transparent;
}

.findings .nav.nav-pills > li > a > .fa {
    margin-right: 5px;
}

.findings .nav.nav-pills > li.active > a,
.findings .nav.nav-pills > li.active > a:hover {
    background-color: #f6f6f6;
    border-top-color: #3c8dbc;
}

.findings .nav.nav-pills > li.active > a {
    font-weight: 600;
}

.findings .nav.nav-pills > li > a:hover {
    background-color: #f6f6f6;
}

/* .findings .nav.nav-pills.nav-stacked > li > a {
    color: #666;
    border-top: 0;
    border-left: 3px solid transparent;
    border-radius: 0px;
} */

.findings .nav.nav-pills.nav-stacked > li.active > a,
.findings .nav.nav-pills.nav-stacked > li.active > a:hover {
    background-color: #f6f6f6;
    border-left-color: #3c8dbc;
    color: #444;
}

.findings .nav.nav-pills.nav-stacked > li.header {
    color: #777;
    text-transform: uppercase;
    position: relative;
    padding: 0px 0 10px 0;
}

.findings table {
    font-weight: 600;
}



.findings table tr.read > td {
    background-color: #f6f6f6;
}

.findings table tr.read > td {
    font-weight: 400;
}

.findings table tr td > i.fa {
    font-size: 1.2em;
    line-height: 1.5em;
    text-align: center;
}

.findings table tr td > i.fa-star {
    color: #f39c12;
}

.findings table tr td > i.fa-bookmark {
    color: #0F1F38;
}

.findings table tr > td.action {
    padding-left: 15px;
    padding-right: 2px;
    width: 30px;
}
.findings table tr > td.name {
    width: 150px;
}

/* .grid {
    position: relative;
    width: 100%;
    background: #fff;
    color: #666666;
    border-radius: 2px;
    margin-bottom: 25px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
} */



.grid .grid-header:after {
    clear: both;
}

.grid .grid-header span,
.grid .grid-header > .fa {
    display: block;
    margin: 0;
    font-weight: 300;
    font-size: 1.5em;
    float: left;
}

.grid .grid-header span {
    padding: 0 5px;
}

.grid .grid-header > .fa {
    padding: 5px 10px 0 0;
}

.grid .grid-header > .grid-tools {
    padding: 4px 10px;
}

.grid .grid-header > .grid-tools a {
    color: #999999;
    padding-left: 10px;
    cursor: pointer;
}

.grid .grid-header > .grid-tools a:hover {
    color: #666666;
}

.grid .grid-body {
    padding: 15px 20px 15px 20px;
    font-size: 0.9em;
    line-height: 1.9em;
}

.grid .full {
    padding: 0 !important;
}

/* .grid .transparent {
    box-shadow: none !important;
    margin: 0px !important;
    border-radius: 0px !important;
} */

.grid.top.black > .grid-header {
    border-top-color: #000000 !important;
}

.grid.bottom.black > .grid-body {
    border-bottom-color: #000000 !important;
}

.grid.top.blue > .grid-header {
    border-top-color: #007be9 !important;
}

.grid.bottom.blue > .grid-body {
    border-bottom-color: #007be9 !important;
}

.grid.top.green > .grid-header {
    border-top-color: #00c273 !important;
}

.grid.bottom.green > .grid-body {
    border-bottom-color: #00c273 !important;
}

.grid.top.purple > .grid-header {
    border-top-color: #a700d3 !important;
}

.grid.bottom.purple > .grid-body {
    border-bottom-color: #a700d3 !important;
}

.grid.top.red > .grid-header {
    border-top-color: #dc1200 !important;
}

.grid.bottom.red > .grid-body {
    border-bottom-color: #dc1200 !important;
}

.grid.top.orange > .grid-header {
    border-top-color: #f46100 !important;
}

.grid.bottom.orange > .grid-body {
    border-bottom-color: #f46100 !important;
}

.grid.no-border > .grid-header {
    border-bottom: 0px !important;
}

.grid.top > .grid-header {
    border-top-width: 4px !important;
    border-top-style: solid !important;
}

.grid.bottom > .grid-body {
    border-bottom-width: 4px !important;
    border-bottom-style: solid !important;
}

/* .page-link .page-link:hover {
    color: #0F1F38;
} */
.exposed-devices {
    color: grey;
    font-weight: normal;
    font-size: 0.9rem;
    font-style: italic;
}


/* .badge.critical {
    color: #fff;
    background-color: rgba(233, 46, 25, 1.0)
}
.badge.high {
    color: #fff;
    background-color: rgba(241, 127, 1, 1.0)
}
.badge.medium {
    color: rgb(255, 255, 255);
    background-color: rgb(231, 225, 31)
}
.badge.low {
    color: #fff;
    background-color: rgba(54, 130, 243, 0.6)
}
.badge.unknown {
    color: #fff;
    background-color: rgba(87, 87, 87, 0.6)
}
.badge.none {
  color: #ffffff;
  background-color: rgba(87, 87, 87, 0.6)
} */


/* Create a custom checkbox */
label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

label:hover {
  cursor: pointer;
}



/* 
.checkbox:active{
  background-color: #f39c12;
} */

.insights {
  margin: 36px;
  height: auto;
}

.show-more {
  color: #cbd1d8;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 28px;
}
