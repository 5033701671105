

.nav-item {
/* background-color: rgba(102, 159, 221, 0.4); */
display: flex;
flex-direction: row;
align-items: flex-start;

position: static;
left: 0%;
right: 80.44%;
top: 0%;
bottom: 0%;
margin-right: 8px;
text-decoration: none;
}

div.nav-item a.link {
    padding: 12px;
    border-radius: 8px;
}

div.nav-item a.link:hover {
  background-color: rgba(102, 159, 221, 0.4);
  text-decoration: none;
}

div.nav-item a.link.active {
    padding: 12px;
    border-radius: 8px;
    background-color: rgba(102, 159, 221, 0.4);
}

.svg {
  position: flex;
  margin-right: 8px;
  margin-top: -2px;
}

.caption{
  position: static;
  left: 31.21%;
  right: 8.51%;
  top: 25%;
  bottom: 25%;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF;
  text-decoration: none;
}

.link:link, .link:visited  {
  display:flex;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}
