table {
  width: 100%;
  height: 100%;
}

.risk-matrix{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;

  border-collapse: separate;
  border-spacing: 0;
}

.td-heading {
    text-align: left;
    padding-right: 20px;
    color: #A6ADB6;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}
.th-heading {
    text-align: center;
    color: #A6ADB6;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    padding-top: 0.63rem;
}

.rotate {
    transform: rotate(-45deg);
}

.table-cell {
    table-layout: fixed;
    text-align: center;
    padding: 6px;
    color: black;
    width: 74px;
    height: 40px;
    border: 1px solid #A6ADB6;
  }

/* Remove duplicate borders from middle Cells*/
tr:nth-child(1) .table-cell:nth-of-type(1),
tr:nth-child(1) .table-cell:nth-of-type(2),
tr:nth-child(1) .table-cell:nth-of-type(3)
{
  border-right: 0;
  border-bottom: 0;
}
tr:nth-child(2) .table-cell:nth-of-type(1),
tr:nth-child(2) .table-cell:nth-of-type(2),
tr:nth-child(2) .table-cell:nth-of-type(3)
{
  border-right: 0;
  border-bottom: 0;
}
tr:nth-child(3) .table-cell:nth-of-type(1),
tr:nth-child(3) .table-cell:nth-of-type(2),
tr:nth-child(3) .table-cell:nth-of-type(3)
{
  border-right: 0;
  border-bottom: 0;
}

/* Remove Borders from Right Cells */
tr:nth-child(1) .table-cell:nth-of-type(4){
  border-bottom: 0;
}
tr:nth-child(2) .table-cell:nth-of-type(4){
  border-bottom: 0;
}
tr:nth-child(3) .table-cell:nth-of-type(4){
  border-bottom: 0;
}
/*Remove Border from last row */
tr:nth-child(4) .table-cell:nth-of-type(1),
tr:nth-child(4) .table-cell:nth-of-type(2),
tr:nth-child(4) .table-cell:nth-of-type(3){
  border-right: 0;
}

tr:nth-child(1) td:nth-child(2){
  border-top-left-radius: 6px;
}
tr:nth-child(1) td:nth-child(5){
  border-top-right-radius: 6px;
}
tr:nth-child(4) td:nth-child(2){
  border-bottom-left-radius: 6px;;
}
tr:nth-child(4) td:nth-child(5){
  border-bottom-right-radius: 6px;
}






