/* Custom fonts */
@font-face {
    font-family: 'Inter-2';
    src: url('./assets/fonts/Inter-2/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
    
}

@font-face {
    font-family: 'D-DIN-Condensed';
    src: url('./assets/fonts/d-din/D-DINCondensed.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'D-DIN';
    src: url('./assets/fonts/d-din/D-DIN-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'D-DIN-Condensed';
    src: url('./assets/fonts/d-din/D-DINCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'D-DIN';
    src: url('./assets/fonts/d-din/D-DIN-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
  font-family: 'Inter-2';
  src: url("../src/assets/fonts/Inter-2/static/Inter-Regular.ttf") format('truetype');
  
}


    

body {
    margin: 0;
    background: #F5F7FC;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    color: #5a5c69;
}

h1 {
    color: #0F1F38;
}

.breadcrumb {
    background-color: transparent;
}
.breadcrumb:not(.active) {
    color: #0F1F38;
}
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #0F1F38;
    content: '\00203A';
}

.breadcrumb-item > a, .breadcrumb-item > a:hover {
    color: #0F1F38;
}

.breadcrumb-item > a, .breadcrumb-item > a:hover {
    color: #0F1F38;
}

