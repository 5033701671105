/*  */

.margin{
  margin-top: 36px;
  margin-right: 52px;
}

.inline {
  display: flex;
  justify-content: space-between;
}

.landscape-title {
  margin-top: 36px;
  margin-left: 46px;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 36px;
  color: #0D276B;

}

.print{
  margin-left: 8px;
  flex-direction: row;
  align-items: center;
  padding: 6px 24px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #0F57A6;
  background-color: transparent;

  /* /Controls: Ocean Blue */

  border: 2px solid #0F57A6;
  box-sizing: border-box;
  border-radius: 34px;
}

.print svg {
  margin-right: 8px;
}

.space-between{
  display: inline-block;
}

.landscape-table{
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
  align-self: center;

}

.landscape-table tbody tr td {
  padding-right: 8px;
}

.landscape-table thead tr th {
  
  position: static;
  height: 48px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 0 15px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 8px;

  /* /Controls: Ocean Blue */
  background: #0F57A6;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;
}

