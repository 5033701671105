.icon {
  display: inline-block;

  /* /Controls: Ocean Blue */
  background: #0F57A6;
  border-radius: 4px;
  color: #FFFFFF;


}

.icon p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 15px;
  margin-bottom: 0;

}
