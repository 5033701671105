#wrapper {
    display: flex;
}


#wrapper #content-wrapper {
    background-color: #ffffff;
    width: 100%;
    overflow-x: hidden;
}

#wrapper #content-wrapper #content {
    flex: 1 0 auto;
}

.cisoscope-bg-gradient-primary {
    background-color: #0F1F38;
}
.text-primary {
    color: #0F1F38 !important;
}

h5 {
    font-size: 1.1rem;
}

.container {
  padding: 0;
}

