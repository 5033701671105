.login-view-container {
  overflow: hidden;
}

.login-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form.login {
  display: flex;
  flex-direction: column;
}

form.login fieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
}

form.login input[type=text] {
  box-sizing: border-box;
  outline: none;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #A6ADB6;
  border-radius: 34px;
  padding: 8px 16px 8px 16px;
  margin: 8px 0px 8px 0px;
  height: 40px;
  width: 320px;
}

form.login input[type=password] {
  box-sizing: border-box;
  outline: none;
  color: #000;
  background: #FFFFFF;
  border: 1px solid #A6ADB6;
  border-radius: 34px;
  padding: 8px 16px 8px 16px;
  margin: 8px 0px 8px 0px;
  height: 40px;
  width: 320px;
}

form.login button.login {
  box-sizing: border-box;
  outline: none;
  color: #F5F7FC;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  background: #0F57A6;
  border: 1px solid #0F57A6;
  border-radius: 34px;
  padding: 8px 16px 8px 16px;
  margin-top: 24px;
  width: 320px;
}

.login-error {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
  padding: 1rem;
}

.login-error h3 {
  align-self: center;
  color: #DA4347;
}

.login-error .error-message {
  color: #506780;
}
