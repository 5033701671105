.links line {
    stroke: #999;
    stroke-opacity: 0.6;
}

.nodes circle {
    stroke: #fff;
    stroke-width: 1.5px;
}

text {
    font-family: sans-serif;
    fontSize: 10px;
}
