
.insight-details-container {
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  padding-left: 24px;
}

.insight-details .row {
  margin: 0;
}

.insight-details .backlink {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
}

.back-btn {
  display: flex;
  align-items: center;
  position: absolute;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #808C9C;
  cursor: pointer;
}

.insight-details a {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.insight-details a span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #808C9C;
  margin-left: 4px;
}

.insight-details .insight-headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;
  margin-left: 1rem;
}

.insight-details .insight-headline span.insight-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 27px;
  color: #0D276B;
  margin-left: 8px;
}

.insight-details .summary-card {
  padding: 32px;
  height: auto;
  color: #0D276B;
}

.insight-details .summary-card td {
  border: none;
}

.insight-details .summary-card .summary {
  padding-left: 0.5rem;
}

.insight-details .summary-card h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #0D276B;
  margin-top: 1rem;
}

.insight-details .vector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 32px;
  align-self: stretch;
}

.insight-details .vector-container .seperator svg {
  margin-left: 8px;
  margin-right: 8px;
}

.insight-details .vector-container .vector-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 24px 36px;
  height: auto;
  align-self: stretch;
  color: #0D276B;
}

.insight-details .vector-container .vector-card h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  color: #0D276B;
}

.insight-details .vector-container .vector-card ul {
  padding-left: 0;
}

.insight-details .vector-container .vector-card li {
  margin-left: 24px;
}

.insight-details .details-section .details-filter-container {
  margin-top: 24px;
}

.insight-details .details-section .details-filter-container ul {
  display: flex;
  flex-direction: row;
  padding-left: 16px;
}

.insight-details .details-section .details-filter-container li {
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 24px;
  margin-right: 24px;
  list-style: none;
  /* background: #0F57A6; */
  font-weight: bold;
  border-radius: 34px;
  color: #0F57A6;
}

.insight-details .details-section .details-filter-container li:hover {
  background: #E0E5F0;
  cursor: pointer;
}

.insight-details .details-section .details-filter-container li.active {
  background: #0F57A6;
  color: #FFFFFF;
}

.insight-details .details-section .details-table-card {
  padding: 32px;
  height: auto;
  color: #0D276B;
}

.insight-details .details-section .details-table-card table th.software {
  width: 16rem;
}

.insight-details .details-section .details-table-card table th.risk {
  width: 6rem;
}

.insight-details .details-section .details-table-card table th.response {
  width: 9rem;
}

.insight-details .details-section .details-table-card table th.subscriber {
  width: 12rem;
}

.insight-details .details-section .details-table-card table th.ticket {
  width: 10rem;
}

.insight-details .details-section .details-table-card table a {
  display: table-cell;
  border: none;
}

.insight-details .details-section .details-table-card table a span {
  display: table-cell;
  border-bottom: none;
  color: #0D276B;
  font-weight: 500;
}

.insight-details .details-section .details-table-card table .subscriber-field {
  flex: 1;
  /* display: table-cell; */
  display: flex;
  flex-direction: column;
  border-bottom: none;
  cursor: pointer;
}

.insight-details .details-section .details-table-card table .no-hover:hover {
  cursor: default;
}

.insight-details .details-section .details-table-card table .subscriber-field .add-subscriber {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #B5C1CE;
}

.insight-details .details-section .details-table-card table .subscriber-field .subscriber-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.insight-details .details-section .details-table-card table .subscriber-field .show-less {
  color: #B5C1CE;
  display: flex;
  flex-direction: row;
}

.subscriber-field .show-less .rotate-180 {
  margin-left: 8px;
  transform: rotate(180deg);
}


.insight-details .details-section .details-table-card table .subscriber-container .subscriber {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px;
  margin: 2px 0 2px 0;
  background: #F5F7FC;
  color: #0D276B;
}

.insight-details .details-section .details-table-card table .subscriber-container .subscriber-rm-btn {
  margin-left: 4px;
  font-size: 16px;
  cursor: pointer;
}

.insight-details .details-section .details-table-card table .subscriber-container .subscriber-more {
  /* margin-left: 4px; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #B5C1CE;
  cursor: pointer;
}

.table-in-card.details tbody tr:hover {
  background-color: #FFFFFF;
  cursor: initial;
}

.insight-details .details-section .details-table-card .no-entries {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  align-self: center;
}
