.self-assesment {
    font-size: 1em;
    background-color: #F5F7FC;
}

.self-assesment .row {
  margin-right: 0;
  margin-left: 0;
}


.pass {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #48A77B;
}

.fail {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #DA4347;
}


.self-assesment tr.no-hover:hover{
  background-color: inherit;
  cursor: default;
}

.heading {
  margin-left: 52px;
  margin-top: 1.5rem;
  color: #0D276B;

  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 27px;
}

.backlink {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  margin-left: 39px;
}

.back-button{
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #808C9C;
  cursor: pointer;
}

.assesment-heading {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 27px;
  color: #0D276B;
  margin-top: 24px;
  margin-left: 36px;
}

.self-assesment-detail {
  height: auto;
  margin: 24px 36px 36px 36px;
  background-color: white;
  color: #0D276B;
}

p {
  margin-bottom: 0;
}

.heading-table{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;

  color: #0D276B;
}

.self-assesment p {
  color: #0D276B;
}

table {
  color: #0D276B;
}

table thead tr th{
  border-bottom: 1px solid #DBE1E8;
  padding-bottom: 7px;
}

table tbody td {
  padding-left: 0;
}