.pagination {
  margin-top: 4px;
  margin-left: 52px;
  margin-right: 52px;
}

.page-item.disabled .page-link {
  border-radius: 8px;
}

.page-item .page-link {
  border-radius: 8px;
  margin: 0 4px;
  width: 36px;
  height: 36px;
  color: grey;
}

.page-item.active .page-link{
  background: #0F57A6;
  border-radius: 8px;
  border-color: #0F57A6;
}

.page-link{
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows {
  display: flex;
  margin-left: auto;
}

.page-item:last-child .page-link{
  border-radius: 8px;
}

.page-item:first-child .page-link{
  border-radius: 8px;
}

.page-item .page-link svg {
  width: 24px;
  height: 12px;
}